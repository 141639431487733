// @ts-nocheck
import React, { useEffect, useState } from "react";
import TestService from "../../services/testService";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  setSidebarStatusModal,
  showLoader,
} from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import "react-datepicker/dist/react-datepicker.css";
import OpticsService from "../../services/opticsService";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ComponentLoader from "../sharedComponents/componentLoader";
// import { OgrenciRaporuOlustur } from "../sharedComponents/testpdf";
import {
  StudentExamReportPDF,
  StudentPracticeExamReportPDF,
  SubClassroomTestReportPDF,
} from "../../utils/Pdf";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";

export default function StudentPanelReportPage() {
  const studentService = new StudentService();
  const testService = new TestService();
  const dispatch = useDispatch();

  const [selectedStudent, setSelectedStudent] = useState<string>("");
  const [reportLoader, setReportLoader] = useState<boolean>(false);
  const [studentPracticeExams, setStudentPracticeExams] = useState<
    Array<PracticeExamOutputModel>
  >([]);
  const [selectedExamForReport, setSelectedExamForReport] = useState<number[]>(
    []
  );
  const [studentReportModalVis, setStudentReportModalVis] =
    useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<string>("");
  const [studentTestReportModalVis, setStudentTestReportModalVis] =
    useState<boolean>(false);
  const [studentTestList, setStudentTestList] = useState<
    Array<TestListByStudentOutputModel>
  >([]);
  const [
    studentSubclassroomReportModalVis,
    setStudentSubclassroomReportModalVis,
  ] = useState<boolean>(false);
  const [selectedTestExamValues, setSelectedTestExamValues] = useState([]);
  const [expandedTestExam, setExpandedTestExam] = useState([]);

  const [selectedPracticeExamValues, setSelectedPracticeExamValues] = useState(
    []
  );
  const [expandedPracticeExam, setExpandedPracticeExam] = useState([]);
  const [studentPracticeExamList, setStudentPracticeExamList] = useState<
    Array<{
      label: string;
      value: string;
      children: {
        headers: {
          lessonName: string;
          startQuestionPosition: number;
          shortLessonName: string;
          questionLength: number;
        }[];
        value: string;
        label: string;
      }[];
    }>
  >([]);

  const [subClassroomTestList, setSubClassroomTestList] = useState([]);
  const [selectedSubTestExamValues, setSelectedSubTestExamValues] = useState(
    []
  );
  const [expandedSubTestExam, setExpandedSubTestExam] = useState([]);

  const [selectedPracticeExamLessons, setSelectedPracticeExamLessons] =
    useState([]);
  const [expandedPracticeExamLessons, setExpandedPracticeExamLessons] =
    useState([]);
  const [practiceExamLessonsList, setPracticeExamLessonsList] = useState<
    Array<{
      label: string;
      value: string;
      children: {
        value: string;
        label: string;
      }[];
    }>
  >([]);

  const getStudentTestList = async () => {
    setReportLoader(true);
    const response = await testService.GetTestForStudentByTestResult();

    const transformedData = response.map((lessonTests, lessonIndex) => {
      const lessonName = lessonTests.lessonName;
      setExpandedTestExam((expandedTestExam) => [
        ...expandedTestExam,
        lessonName,
      ]);
      const transformedLesson = {
        label: lessonName,
        value: lessonName,
        children: lessonTests.tests.map((test, index) => {
          setSelectedTestExamValues((selectedTestExamValues) => [
            ...selectedTestExamValues,
            `${test.id}-${index}`,
          ]);
          return {
            value: `${test.id}-${index}`,
            label: `${test.name} - ${test.issueDate}`,
          };
        }),
      };

      return transformedLesson;
    });

    setStudentTestList(transformedData);
    setReportLoader(false);
  };

  const getStudentPracticeExamList = async () => {
    setReportLoader(true);

    const response = await testService.GetStudentPracticeExams(selectedId);
    const groupedData = response.reduce((groups, item) => {
      const practiceExamType = item.practiceExam.practiceExamType.name;

      if (!groups[practiceExamType]) {
        groups[practiceExamType] = [];
      }

      groups[practiceExamType].push(item);

      return groups;
    }, {});

    const mappedData = Object.entries(groupedData).map(([type, exams]) => ({
      practiceExamType: type,
      lessons: exams[0].lesson,
      exams: exams.map((item, index) => ({
        ...item,
        practiceExam: {
          ...item.practiceExam,
          id2: `${item.practiceExamResultId}${index}${item.id}`,
        },
      })),
    }));

    console.warn(mappedData);

    const transformedData = mappedData.map((lessonTests, lessonIndex) => {
      const lessonName = lessonTests.practiceExamType;
      setExpandedPracticeExam((expandedPracticeExam) => [
        ...expandedPracticeExam,
        lessonName,
      ]);
      const transformedLesson = {
        label: lessonName,
        value: lessonName,
        headers: lessonTests.lessons,
        children: lessonTests.exams.map((test, index) => {
          return {
            value: `${test.practiceExam.id}`,
            // value: `${randomId}`,µ
            label: `${test.practiceExam.name}`,
          };
        }),
      };

      return transformedLesson;
    });
    setStudentPracticeExamList(transformedData);
    setReportLoader(false);
  };

  const getStudentReport = async () => {
    setReportLoader(true);
    let mappedData = [];
    selectedPracticeExamValues.map((item) => {
      mappedData = [...mappedData, Number(item)];
    });
    const response = await testService.GetPracticeExamReportForStudent(
      mappedData
    );
    const reportData = {
      ...response,
      ...{
        headers: [...selectedPracticeExamLessons, "Toplam"],
      },
    };

    await StudentPracticeExamReportPDF(reportData);
    setReportLoader(false);
  };

  const getStudentTestReport = async () => {
    setReportLoader(true);
    let mappedData = [];
    selectedTestExamValues.map((item) => {
      mappedData = [...mappedData, Number(item.split("-")[0])];
    });
    const response = await testService.GetTestResultReportForstudent([
      ...new Set(mappedData),
    ]);
    await StudentExamReportPDF(response);
    setReportLoader(false);
  };

  const getSubClassroomTestReport = async () => {
    setReportLoader(true);
    let mappedData = [];
    selectedTestExamValues.map((item) => {
      mappedData = [...mappedData, Number(item)];
    });

    const response = await testService.GetTestResultReportForSubclassroom(
      mappedData
    );
    SubClassroomTestReportPDF(response);
    setReportLoader(false);
  };

  const getSubClassroomTestExamList = async () => {
    setReportLoader(true);
    const response = await testService.GetTestForSubclassroomByTestResult();
    const transformedData = response.map((lessonTests, lessonIndex) => {
      const lessonName = lessonTests.lessonName;
      setExpandedTestExam((expandedTestExam) => [
        ...expandedTestExam,
        lessonName,
      ]);
      const transformedLesson = {
        label: lessonName,
        value: lessonName,
        children: lessonTests.tests.map((test, index) => {
          setSelectedTestExamValues((selectedTestExamValues) => [
            ...selectedTestExamValues,
            `${test.id}`,
          ]);
          return {
            value: `${test.id}`,
            label: `${test.name} - ${test.issueDate}`,
          };
        }),
      };

      return transformedLesson;
    });
    setSubClassroomTestList(transformedData);
    setReportLoader(false);
  };

  useEffect(() => {
    if (!!selectedPracticeExamValues.length) {
      const exam = studentPracticeExamList.filter((element) =>
        element.children.some(
          (subElement) => subElement.value === selectedPracticeExamValues[0]
        )
      );

      const data = exam[0].headers.map((header) => {
        setSelectedPracticeExamLessons((selectedPracticeExamLessons) => [
          ...selectedPracticeExamLessons,
          header,
        ]);
        return { value: header, label: header };
      });

      const transformedLesson = [
        {
          label: "Dersler",
          value: "Dersler",
          children: data,
        },
      ];
      setExpandedPracticeExamLessons(["Dersler"]);
      setPracticeExamLessonsList(transformedLesson);
    } else {
      setExpandedPracticeExamLessons([]);
      setPracticeExamLessonsList([]);
    }
  }, [selectedPracticeExamValues]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center align-items-center pt-2">
              <button
                type="button"
                onClick={(e) => {
                  getStudentPracticeExamList();
                  setStudentReportModalVis(true);
                  setSelectedPracticeExamValues([]);
                }}
                className="btn btn-primary ml-1"
                data-bs-dismiss="modal"
              >
                <span className="d-inline p-2">Deneme Raporlarım</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center align-items-center pt-2">
              <button
                type="button"
                onClick={(e) => {
                  // getStudentExamsReport();
                  getStudentTestList();
                  setStudentTestReportModalVis(true);
                  setSelectedExamForReport([]);
                }}
                className="btn btn-success ml-1"
                data-bs-dismiss="modal"
              >
                <span className="d-inline p-2">Sınav Raporlarım</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center align-items-center pt-2">
              <button
                type="button"
                onClick={(e) => {
                  getSubClassroomTestExamList();
                  setStudentSubclassroomReportModalVis(true);
                }}
                className="btn btn-info ml-1"
                data-bs-dismiss="modal"
              >
                <span className="d-inline p-2">Şube Raporlarım</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {studentReportModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Deneme Rapor
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                {reportLoader ? (
                  <ComponentLoader />
                ) : (
                  <div className="row">
                    <div className="col-3">
                      <CheckboxTree
                        nativeCheckboxes
                        nodes={studentPracticeExamList}
                        checked={selectedPracticeExamValues}
                        expanded={expandedPracticeExam}
                        onCheck={setSelectedPracticeExamValues}
                        onExpand={setExpandedPracticeExam}
                        showNodeIcon={false}
                      />
                    </div>
                    <div className="col-3">
                      <CheckboxTree
                        nativeCheckboxes
                        nodes={practiceExamLessonsList}
                        checked={selectedPracticeExamLessons}
                        expanded={expandedPracticeExamLessons}
                        onCheck={setSelectedPracticeExamLessons}
                        onExpand={setExpandedPracticeExamLessons}
                        showNodeIcon={false}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setStudentReportModalVis(false);
                    setSelectedExamForReport([]);
                    setStudentPracticeExams([]);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    getStudentReport();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={selectedPracticeExamValues.length == 0}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Rapor Oluştur</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {studentTestReportModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Sınav Rapor
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                {reportLoader ? (
                  <ComponentLoader />
                ) : (
                  <CheckboxTree
                    nativeCheckboxes
                    nodes={studentTestList}
                    checked={selectedTestExamValues}
                    expanded={expandedTestExam}
                    onCheck={setSelectedTestExamValues}
                    onExpand={setExpandedTestExam}
                    showNodeIcon={false}
                  />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setStudentTestReportModalVis(false);
                    setSelectedExamForReport([]);
                    setStudentPracticeExams([]);
                    setSelectedTestExamValues([]);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    // getStudentReport();
                    getStudentTestReport();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={selectedTestExamValues.length == 0}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Rapor Oluştur</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {studentSubclassroomReportModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Şube Rapor
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                {reportLoader ? (
                  <ComponentLoader />
                ) : (
                  <CheckboxTree
                    nativeCheckboxes
                    nodes={subClassroomTestList}
                    checked={selectedSubTestExamValues}
                    expanded={expandedSubTestExam}
                    onCheck={setSelectedSubTestExamValues}
                    onExpand={setExpandedSubTestExam}
                    showNodeIcon={false}
                  />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setStudentSubclassroomReportModalVis(false);
                    setSelectedExamForReport([]);
                    setStudentPracticeExams([]);
                    setSelectedTestExamValues([]);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    // getStudentReport();
                    getSubClassroomTestReport();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={selectedTestExamValues.length == 0}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Rapor Oluştur</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
