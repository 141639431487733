import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function WebFooter() {
  return (
    <>
      <div className="section section-sm line-bottom-light">
        <div className="container">
          <div className="row row-grid align-items-center mb-3">
            <div className="col-lg-6">
              <h3 className="text-warning heading-title mb-2">
                Sosyal Medya Hesaplarımız
              </h3>
              <h4 className="mb-0 font-weight-light">
                Bizi Sosyal Medya hesaplarımızdan takip edin, Yenilikleri,
                Haberleri ve Kampanyaları kaçırmayın.
              </h4>
            </div>
            <div className="col-lg-6 text-lg-center btn-wrapper">
              <a
                href="https://www.facebook.com/gumusegitimozel"
                target="_blank"
              >
                <button
                  className="btn mb-2 mr-2 btn-icon-only rounded-circle btn-facebook"
                  type="button"
                >
                  <span className="btn-inner-icon">
                    <i className="fab fa-facebook-f"></i>
                  </span>
                </button>
              </a>

              <a
                href="https://instagram.com/gumusegitimozel"
                target="_blank"
                className="btn-inner-icon text-white"
              >
                <button
                  className="btn mb-2 mr-2 btn-icon-only rounded-circle btn-instagram"
                  type="button"
                >
                  <i className="fab fa-instagram"></i>
                </button>
              </a>
              <a href="https://twitter.com/emregumuus" target="_blank">
                <button
                  className="btn mb-2 mr-2 btn-icon-only rounded-circle btn-twitch"
                  type="button"
                >
                  <span className="btn-inner-icon">
                    <i className="fab fa-twitter"></i>
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer section pt-4 pt-md-5 pt-lg-6 pb-3 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <a className="footer-brand mr-lg-5 d-flex">
                <img
                  src="../globalLogo.png"
                  height="35"
                  className="mr-3"
                  alt="Footer logo"
                />
              </a>
              <div className="my-4">
                <h6>İstanbul Merkez Şube</h6>
                <strong>Adres: </strong>
                <a href="https://goo.gl/maps/gf9mkq2m8JzHs7wJA" target="_blank">
                  {" "}
                  İncirli Caddesi Tunaboylu Sokak No:1/10 Bakırköy/İstanbul
                </a>
                <br />
                <strong>Telefon: </strong>{" "}
                <a href="tel:+905365079340">536 507 93 40</a> /{" "}
                <a href="tel:+902125716616">212 571 66 16</a>
                <br />
                <strong>Email </strong>{" "}
                <a href="mailto:gumusegitimbakirkoy@gmail.com">
                  gumusegitimbakirkoy@gmail.com
                </a>
              </div>
              <div className="my-4">
                <h6>Samsun Şubesi</h6>
                <strong>Adres: </strong>
                <a href="https://goo.gl/maps/yek4YBwwy3ecmVMX8" target="_blank">
                  {" "}
                  Bahçelievler Mah. Uhut Sok. No:19 İLKADIM/SAMSUN
                </a>
                <br />
                <strong>Telefon: </strong>
                <a href="tel:+905365079340">536 507 93 40</a> /{" "}
                <a href="tel:+903622342255">362 234 22 55</a>
                <br />
                <strong>Email </strong>{" "}
                <a href="mailto:gumusegitim55@gmail.com">
                  gumusegitim55@gmail.com
                </a>
                <small className="mt-2 form-text">
                  Daha fazla iletişim bilgilerimiz için{" "}
                  <a
                    href="https://gumusegitim.com/iletisim"
                    className="font-weight-bold text-underline"
                  >
                    İletişim
                  </a>
                </small>
              </div>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 mb-4 mb-lg-0">
              <h6>Menu</h6>
              <ul className="links-vertical">
                <li>
                  <a href="https://gumusegitim.com">Anasayfa</a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/hakkimizda">Hakkımızda</a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/iletisim">İletişim</a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/SSS">SSS</a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 mb-4 mb-lg-0">
              <h6>Servisler</h6>
              <ul className="links-vertical">
                <li>
                  <a href="https://gumusegitim.com/ozelders">Özel Ders</a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/onlinebirebirders">
                    Online Bire-Bir Ders
                  </a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/lgs">LGS</a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/yks">YKS(TYT-AYT)</a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/oaho">
                    Okudugunu Anlama ve Hızlı Okuma
                  </a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/arasinifyazilicalismasi">
                    Ara Sınıf Yazılı Çalışması
                  </a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/grupdersleri">
                    Grup Dersleri
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <h6>Diğer</h6>
              <ul className="links-vertical">
                <li>
                  <a href="https://gumusegitim.com/gizlilikpolitikasi">
                    Gizlilik Politikası
                  </a>
                </li>
                <li>
                  <a href="https://gumusegitim.com/kullanimkosullari">
                    Kullanım Koşulları
                  </a>
                </li>
                {/* <li><Link to="/blog">Blog</Link></li> */}
              </ul>
            </div>
          </div>
          <hr className="my-4 my-lg-5" />
          <div className="row">
            <div className="col pb-4 mb-md-0">
              <div className="d-flex text-center justify-content-center align-items-center">
                <p className="font-weight-normal mb-0">
                  ©{" "}
                  <li>
                    <a href="https://gumusegitim.com">Gümüş Eğitim</a>
                  </li>
                  <span className="current-year"></span> Tüm hakları saklıdır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
