import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import StudentsWidget from "./StudentsWidget";
import SubClassroomWidget from "./SubClassroomWidget";
import TasksWidget from "./TasksWidget";

export default function TeacherMainpage() {
  const teacherContext = useContext(TeacherIdentityContext);

  return (
    <div className="page-content">
      <section className="row">
        <div className="col-12 col-lg-12">
          <div className="row">
            <div className="col-8 col-lg-4 col-md-12">
              <div className="card">
                <div className="card-body px-3 py-4-5">
                  <div className="row">
                    <div className="col-md-4 avatar avatar-lg">
                      <img
                        src={
                          teacherContext.profilePhoto ||
                          "/assets/img/Profile_avatar_placeholder_large.png"
                        }
                        alt="Face 1"
                      />
                    </div>
                    <div className="col-md-8">
                      <h5 className="font-bold">
                        {teacherContext.name + " " + teacherContext.surname}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-6 ">
              <div className="card">
                <div className="card-body px-3 py-4-5">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="stats-icon red">
                        <i className="iconly-boldStar"></i>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h6 className="text-muted font-semibold">Branş</h6>
                      <h6 className="font-extrabold mb-0">
                        {teacherContext.majors
                          .map((m) => m.lessonName)
                          .join(", ")}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-6">
              <div className="card">
                <div className="card-body px-3 py-4-5">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="stats-icon green">
                        <i className="iconly-boldTime-Circle"></i>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h6 className="text-muted font-semibold">Dönem</h6>
                      <h6 className="font-extrabold mb-0">
                        {/* {new Date().getFullYear()} */}
                        2024-2025
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <StudentsWidget />
        </div>
        <div className="col-12 col-lg-4">
          <SubClassroomWidget />
        </div>
        <div className="col-12 col-lg-4">
          <TasksWidget />
        </div>
      </section>
    </div>
  );
}
