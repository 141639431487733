import React, { useContext, useEffect } from "react";
import { StudentIdentityContext } from "../panels/studentPanel";

export default function StudentBasicInfo() {
  const studentContext = useContext(StudentIdentityContext);
  return (
    <div className="col-12 col-lg-12">
      <div className="row">
        <div className="col-12 col-lg-4 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4 avatar avatar-lg">
                  <img
                    src={
                      studentContext.profilePhoto ||
                      "/assets/img/Profile_avatar_placeholder_large.png"
                    }
                    alt="Face 1"
                  />
                </div>
                <div className="col-md-8">
                  <h5 className="font-bold">
                    {studentContext.name + " " + studentContext.surname}
                  </h5>
                  <h6 className="text-muted mb-0"></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-3">
                  <div className="stats-icon red">
                    <i className="iconly-boldHome"></i>
                  </div>
                </div>
                <div className="col-md-9">
                  <h6 className="text-muted font-semibold">Sınıf</h6>
                  <h6 className="font-extrabold mb-0">
                    {studentContext.gumusEgitimClassroom}{" "}
                    {studentContext.subclassroom !== null &&
                      "- " + studentContext?.subclassroom?.name + "Şubesi"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-3">
                  <div className="stats-icon green">
                    <i className="iconly-boldTime-Circle"></i>
                  </div>
                </div>
                <div className="col-md-9">
                  <h6 className="text-muted font-semibold">Dönem</h6>
                  <h6 className="font-extrabold mb-0">
                    {/* {new Date().getFullYear()} */}
                    2024-2025
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
