import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useScript from "../../hooks/useScript";
import { setSidebarStatus, showLoader } from "../../reducers/commonSlice";
import AuthorizationService from "../../services/authorizationService";
import RoleService, { Roles } from "../../services/roleService";
import { RootState } from "../../store";

export default function AdminSideBar() {
  let history = useHistory();
  const dispatch = useDispatch();
  const sidebarStatus = useSelector(
    (state: RootState) => state.commons.sidebarStatus
  );
  const activeTab = history.location.pathname
    .split("/")
    .pop()
    .toLocaleLowerCase();
  const activeTabArray = history.location.pathname.split("/");

  const [roles, setRoles] = useState<Array<string>>(null);
  const logout = async () => {
    let authorizationService = new AuthorizationService();
    dispatch(showLoader());
    await authorizationService.Logout();
    //dispatch(hideLoader());
    // if (result) history.push("/login");
  };

  const getUserRoles = async () => {
    const roleService = new RoleService();
    const result = await roleService.GetUserRoles();
    setRoles(result);
  };

  useEffect(() => {
    getUserRoles();
    dispatch(setSidebarStatus(false));
  }, [history.location]);

  useScript("/assets/js/main.js");
  const [isBranchesOpen, setIsBranchesOpen] = useState<boolean>(false);

  const handleClickBranch = () => {
    setIsBranchesOpen((isBranchesOpen) => !isBranchesOpen);
  };

  return (
    <div id="sidebar">
      <div
        className="sidebar-wrapper"
        style={{ left: sidebarStatus ? 0 : null }}
      >
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/admin">
                <img
                  src="/globalLogo.png"
                  width="100%"
                  height="100%"
                  alt="Logo"
                  srcSet=""
                />
              </Link>
            </div>
            <div className="toggler">
              <a
                onClick={() => dispatch(setSidebarStatus(false))}
                className="sidebar-hide d-xl-none d-block"
              >
                <i className="bi bi-x bi-middle"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-title">Menuler</li>

            <li
              className={
                "sidebar-item " + (activeTab == "admin" ? "active" : "")
              }
            >
              <Link to="/admin" className="sidebar-link">
                <i className="bi bi-grid-fill"></i>
                <span>Gösterge Paneli</span>
              </Link>
            </li>

            {/*  Kurum */}
            <div
              style={{
                display: (roles || []).includes(Roles.Admin)
                  ? "inline"
                  : "none",
              }}
            >
              <li
                className={
                  "sidebar-item has-sub " +
                  ((activeTab == "branches" ||
                    activeTabArray.includes("branchesClassrooms") ||
                    activeTabArray.includes("branches") ||
                    activeTabArray.includes("branche")) &&
                  !activeTabArray.includes("report")
                    ? "active"
                    : "")
                }
              >
                <a className="sidebar-link" onClick={handleClickBranch}>
                  <i className="fa fa-building"></i>
                  <span>Kurum</span>
                </a>
                <ul className={`submenu  ${isBranchesOpen ? "active" : ""}`}>
                  <li
                    className={
                      "submenu-item " +
                      (activeTab == "branches" ? "active" : "")
                    }
                  >
                    <Link to="/admin/branches">Şubeler</Link>
                  </li>
                  <li
                    className={
                      "submenu-item " +
                      (activeTabArray.includes("branchesClassrooms") ||
                      activeTabArray.includes("subClassroom")
                        ? "active"
                        : "")
                    }
                  >
                    <Link to="/admin/branchesClassrooms">Sınıflar</Link>
                  </li>
                </ul>
              </li>
            </div>

            {/* sınavlar */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTab == "exams" ||
                activeTab == "practiceexams" ||
                activeTab == "optics"
                  ? "active"
                  : "")
              }
            >
              <a className="sidebar-link">
                <i className="bi bi-collection-fill"></i>
                <span>Sınavlar</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " + (activeTab == "exams" ? "active" : "")
                  }
                >
                  <Link to="/admin/exams">Sınavlar</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTab == "practiceexams" ? "active" : "")
                  }
                >
                  <Link to="/admin/practiceExams">Denemeler</Link>
                </li>
                <li
                  className={
                    "submenu-item " + (activeTab == "optics" ? "active" : "")
                  }
                >
                  <Link to="/admin/optics">Optikler</Link>
                </li>
              </ul>
            </li>

            {/* Anket */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTab == "survey" || activeTab == "surveyresults"
                  ? "active"
                  : "")
              }
            >
              <a className="sidebar-link">
                <i className="bi bi-clipboard-data"></i>
                <span>Anket</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " + (activeTab == "survey" ? "active" : "")
                  }
                >
                  <Link to="/admin/survey">Anket Oluştur</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTab == "surveyresults" ? "active" : "")
                  }
                >
                  <Link to="/admin/surveyresults">Anket Sonuçları</Link>
                </li>
              </ul>
            </li>
            {/* Raporlama */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTabArray.includes("report") ? "active" : "")
              }
            >
              <a className="sidebar-link">
                <i className="fa fa-line-chart"></i>
                <span>Raporlama</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " + (activeTab == "student" ? "active" : "")
                  }
                >
                  <Link to="/admin/report/student">Öğrenci Rapor</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("subClassroom") &&
                    activeTabArray.includes("report")
                      ? "active"
                      : "")
                  }
                >
                  <Link
                    to={
                      (roles || []).includes(Roles.Admin)
                        ? "/admin/report/branchesClassrooms"
                        : "/admin/report/inClassrooms"
                    }
                  >
                    Şube İçi Rapor
                  </Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("subClassroom") &&
                    activeTabArray.includes("report")
                      ? "active"
                      : "")
                  }
                >
                  <Link
                    to={
                      (roles || []).includes(Roles.Admin)
                        ? "/admin/report/outBranchesClassrooms"
                        : "/admin/report/outClassrooms"
                    }
                  >
                    Şubeler Arası Rapor
                  </Link>
                </li>
              </ul>
            </li>

            {/* Dersler */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTab == "lessons" || activeTab == "classlessons"
                  ? "active"
                  : "")
              }
            >
              <a className="sidebar-link">
                <i className="fas fa-book"></i>
                <span>Dersler</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " + (activeTab == "lessons" ? "active" : "")
                  }
                >
                  <Link to="/admin/lessons">Bütün Dersler</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTab == "classlessons" ? "active" : "")
                  }
                >
                  <Link to="/admin/classlessons">Sınıf Dersleri</Link>
                </li>
              </ul>
            </li>

            {/* Sınıflar */}
            <div
              style={{
                display: (roles || []).includes(Roles.Admin)
                  ? "none"
                  : "inline",
              }}
            >
              <li
                className={
                  "sidebar-item " + (activeTab == "classrooms" ? "active" : "")
                }
              >
                <Link to="/admin/classrooms" className="sidebar-link">
                  <i className="fas fa-chalkboard"></i>
                  <span>Sınıflar</span>
                </Link>
              </li>
            </div>

            {/* Soru bankası havuzu */}
            <li
              className={
                "sidebar-item " + (activeTab == "questionbank" ? "active" : "")
              }
            >
              <Link to="/admin/questionbank" className="sidebar-link">
                <i className="fas fa-question-circle"></i>
                <span>Soru Bankası Havuzu</span>
              </Link>
            </li>

            {/* PDF ekle */}
            <li
              className={
                "sidebar-item " + (activeTab == "createtest" ? "active" : "")
              }
            >
              <Link to="/admin/createtest" className="sidebar-link">
                <i className="fa fa-file-pdf-o"></i>
                <span>PDF Ekle</span>
              </Link>
            </li>

            {/* Kullanıcılar */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTab == "teachers" ||
                activeTab == "students" ||
                activeTab == "managers"
                  ? "active"
                  : "")
              }
            >
              <a className="sidebar-link">
                <i className="bi bi-people-fill"></i>
                <span>Kullanıcılar</span>
              </a>
              <ul className="submenu ">
                {(roles || []).includes(Roles.Admin) ? (
                  <li
                    className={
                      "submenu-item " +
                      (activeTab == "managers" ? "active" : "")
                    }
                  >
                    <Link to="/admin/managers">Yöneticiler</Link>
                  </li>
                ) : null}
                <li
                  className={
                    "submenu-item " + (activeTab == "teachers" ? "active" : "")
                  }
                >
                  <Link to="/admin/teachers">Öğretmenler</Link>
                </li>
                <li
                  className={
                    "submenu-item " + (activeTab == "students" ? "active" : "")
                  }
                >
                  <Link to="/admin/students">Öğrenciler</Link>
                </li>
              </ul>
            </li>

            {/* Email */}
            <li
              className={
                "sidebar-item " + (activeTab == "email" ? "active" : "")
              }
            >
              <Link to="/admin/email" className="sidebar-link">
                <i className="fas fa-mail-bulk"></i>
                <span>E-mail</span>
              </Link>
            </li>

            {/* Görevler */}
            <li
              className={
                "sidebar-item " + (activeTab == "tasks" ? "active" : "")
              }
            >
              <Link to="/admin/tasks" className="sidebar-link">
                <i className="fa fa-tasks"></i>
                <span>Görevler</span>
              </Link>
            </li>

            {/* Görüşmeler */}
            <li
              className={
                "sidebar-item " + (activeTab == "meets" ? "active" : "")
              }
            >
              <Link to="/admin/meets" className="sidebar-link">
                <i className="fa fa-comments"></i>
                <span>Görüşmeler</span>
              </Link>
            </li>

            {/* Rehberlik */}
            <li
              className={
                "sidebar-item " + (activeTab == "guidance" ? "active" : "")
              }
            >
              <Link to="/admin/guidance" className="sidebar-link">
                <i className="fa fa-sticky-note"></i>
                <span>Rehberlik</span>
              </Link>
            </li>

            {/* Sınıf Defteri */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTabArray.includes("classBook") ? "active" : "")
              }
              style={{
                display: (roles || []).includes(Roles.Admin)
                  ? "none"
                  : "inline",
              }}
            >
              <a className="sidebar-link">
                <i className="fa fa-book"></i>
                <span>Sınıf Defteri</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("subjectBook") ? "active" : "")
                  }
                >
                  <Link to="/admin/classBook/subjectBook">Sınıf Defteri</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("checkBook") ? "active" : "")
                  }
                >
                  <Link to="/admin/classBook/checkBook">Yoklama Defteri</Link>
                </li>
              </ul>
            </li>

            <li className="sidebar-item">
              <a
                className="sidebar-link"
                onClick={logout}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-plug-fill"></i>
                <span>Çıkış Yap</span>
              </a>
            </li>
            <li className="sidebar-title">&nbsp;</li>
            <li className="sidebar-item">
              <a
                className="sidebar-link btn-facebook"
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-long-arrow-alt-left text-white"></i>
                <Link to="/">
                  <span className="text-white">Web'e Geri Dön</span>
                </Link>
              </a>
            </li>
          </ul>
        </div>
        <button className="sidebar-toggler btn x">
          <i data-feather="x"></i>
        </button>
      </div>
    </div>
  );
}
