import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useScript from "../../hooks/useScript";
import {
  hideLoader,
  setSidebarStatus,
  showLoader,
} from "../../reducers/commonSlice";
import AccountService from "../../services/accountService";
import AuthorizationService from "../../services/authorizationService";
import ClassroomService from "../../services/classroomService";
import RoleService from "../../services/roleService";
import { RootState } from "../../store";
import { TeacherIdentityContext } from "../panels/teacherPanel";

export default function TeacherSideBar() {
  const roleService = new RoleService();
  const accountService = new AccountService();
  const teacherContext = useContext(TeacherIdentityContext);

  let history = useHistory();
  const dispatch = useDispatch();
  const sidebarStatus = useSelector(
    (state: RootState) => state.commons.sidebarStatus
  );
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const activeTab = history.location.pathname
    .split("/")
    .pop()
    .toLocaleLowerCase();
  const activeTabArray = history.location.pathname.split("/");

  const logout = async () => {
    let authorizationService = new AuthorizationService();
    dispatch(showLoader());
    await authorizationService.Logout();
    dispatch(hideLoader());
    // if (result) history.push("/login");
  };

  const getUserRoles = async () => {
    const result = await roleService.GetUserRoles();
    setIsAdminUser(result.includes("admin") || result.includes("manager"));
  };

  const exitHostAccount = () => {
    dispatch(showLoader());
    accountService.exitHostAccount("/teachers");
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    dispatch(setSidebarStatus(false));
  }, [history.location]);

  useScript("/assets/js/main.js");

  return (
    <div id="sidebar" className={sidebarActive ? "active" : ""}>
      <div
        className="sidebar-wrapper"
        style={{ left: sidebarStatus ? 0 : null }}
      >
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/teacher">
                <img
                  src="/globalLogo.png"
                  width="100%"
                  height="100%"
                  alt="Logo"
                  srcSet=""
                />
              </Link>
            </div>
            <div className="toggler">
              <a
                onClick={() => dispatch(setSidebarStatus(false))}
                className="sidebar-hide d-xl-none d-block"
              >
                <i className="bi bi-x bi-middle"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-title">Menuler</li>

            {/* Gösterge Paneli */}
            <li
              className={
                "sidebar-item " + (activeTab == "teacher" ? "active" : "")
              }
            >
              <Link to="/teacher" className="sidebar-link">
                <i className="bi bi-grid-fill"></i>
                <span>Gösterge Paneli</span>
              </Link>
            </li>

            {/* Profilim */}
            <li
              className={
                "sidebar-item " + (activeTab == "profile" ? "active" : "")
              }
            >
              <Link to="/teacher/profile" className="sidebar-link">
                <i className="fas fa-user-alt"></i>
                <span>Profilim</span>
              </Link>
            </li>

            {/* Sınıflar */}
            <li
              className={
                "sidebar-item has-sub " +
                (history.location.pathname.includes("classroom")
                  ? "active"
                  : "")
              }
            >
              <a className="sidebar-link">
                <i className="bi bi-stack"></i>
                <span>Sınıflar</span>
              </a>
              <ul className="submenu">
                {teacherContext.classrooms == null ||
                teacherContext.classrooms.length == 0 ? (
                  <li className="submenu-item ">
                    <div className="sidebar-link">
                      <span></span>
                    </div>
                  </li>
                ) : (
                  teacherContext.classrooms.map((c) => (
                    <li
                      className={
                        "submenu-item " +
                        (activeTab == c.id.toString() ? "active" : "")
                      }
                    >
                      <Link to={`/teacher/classroom/${c.id}`}>{c.name}</Link>
                    </li>
                  ))
                )}
              </ul>
            </li>

            {/* Raporlama */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTabArray.includes("report") ? "active" : "")
              }
            >
              <a className="sidebar-link">
                <i className="fa fa-line-chart"></i>
                <span>Raporlama</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " + (activeTab == "student" ? "active" : "")
                  }
                >
                  <Link to="/teacher/report/student">Öğrenci Rapor</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("subClassroom") &&
                    activeTabArray.includes("report")
                      ? "active"
                      : "")
                  }
                >
                  <Link to="/teacher/report/subClassroom">Şube Rapor</Link>
                </li>
              </ul>
            </li>

            {/* sınavlar */}
            <li
              className={
                "sidebar-item " + (activeTab == "exams" ? "active" : "")
              }
            >
              <Link to="/teacher/exams" className="sidebar-link">
                <i className="bi bi-collection-fill"></i>
                <span>Sınavlar</span>
              </Link>
            </li>

            {/* PDF Ekle */}
            <li
              className={
                "sidebar-item " +
                (activeTabArray.includes("addPdf") ? "active" : "")
              }
            >
              <Link to="/teacher/addPdf" className="sidebar-link">
                <i className="fa fa-file-pdf-o"></i>
                <span>PDF Ekle</span>
              </Link>
            </li>

            {/* Soru bankası havuzu */}
            <li
              className={
                "sidebar-item " +
                (activeTabArray.includes("questionbank") ? "active" : "")
              }
            >
              <Link to="/teacher/questionbank" className="sidebar-link">
                <i className="fas fa-question-circle"></i>
                <span>Soru Bankası Havuzu</span>
              </Link>
            </li>

            {/* PDF İşlemleri */}
            <li
              className={
                "sidebar-item " +
                (activeTabArray.includes("createtest") ? "active" : "")
              }
            >
              <Link to="/teacher/createtest" className="sidebar-link">
                <i className="fa fa-plus-circle"></i>
                <span>Test Oluştur</span>
              </Link>
            </li>

            {/* Görüşmeler */}
            <li
              className={
                "sidebar-item " + (activeTab == "meets" ? "active" : "")
              }
            >
              <Link to="/teacher/meets" className="sidebar-link">
                <i className="fa fa-comments"></i>
                <span>Görüşmeler</span>
              </Link>
            </li>

            {/* Sınıf Defteri */}
            <li
              className={
                "sidebar-item has-sub " +
                (activeTabArray.includes("classBook") ? "active" : "")
              }
            >
              <a className="sidebar-link">
                <i className="fa fa-book"></i>
                <span>Sınıf Defteri</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("subjectBook") ? "active" : "")
                  }
                >
                  <Link to="/teacher/classBook/subjectBook">Sınıf Defteri</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("checkBook") ? "active" : "")
                  }
                >
                  <Link to="/teacher/classBook/checkBook">Yoklama Defteri</Link>
                </li>
              </ul>
            </li>

            {/* Çıkış Yap */}
            <li className="sidebar-item">
              <a
                className="sidebar-link"
                onClick={logout}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-plug-fill"></i>
                <span>Çıkış Yap</span>
              </a>
            </li>

            <li className="sidebar-title">&nbsp;</li>

            {isAdminUser ? (
              <li className={"sidebar-item"}>
                <a
                  onClick={exitHostAccount}
                  style={{ cursor: "pointer" }}
                  className="sidebar-link btn-facebook"
                >
                  <i className="fas fa-directions text-white"></i>
                  <span className="text-white">Admin Panele Dön</span>
                </a>
              </li>
            ) : null}
            <li className="sidebar-item">
              <a
                className="sidebar-link btn-facebook"
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-long-arrow-alt-left text-white"></i>
                <Link to="/">
                  <span className="text-white">Web'e Geri Dön</span>
                </Link>
              </a>
            </li>
          </ul>
        </div>
        <button className="sidebar-toggler btn x">
          <i data-feather="x"></i>
        </button>
      </div>
    </div>
  );
}
